import React from 'react';
import { connect } from 'react-redux';

import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button
} from 'reactstrap';
import { Switch, Redirect, Route, Link, NavLink } from 'react-router-dom';
import Dynamic from '../Dynamic/Dynamic';
import pageComponents from './nnw2024/';

const pracownicyData = [
  {
    title: 'Informacje ogólne',
    component: 'Ogolne2',
    link: 'opis/'
  }
];

class PublicTwoColumnTemplate extends React.Component {
  render() {
    let currentBreadcrumb;

    const menu = pracownicyData.map((item) => {
      if (item.externalLink) {
        return (
          <ListGroupItem
            tag="a"
            key={item.title}
            href={item.externalLink}
            target="_blank"
            rel="noopener noreferrer"
            action>
            {item.title}
          </ListGroupItem>
        );
      }

      if (
        !currentBreadcrumb &&
        (`${this.props.match.path}${item.link}` ===
          this.props.location.pathname ||
          `${this.props.match.path}${item.link}` ===
            `${this.props.location.pathname}/`)
      ) {
        currentBreadcrumb = (
          <BreadcrumbItem active>{item.title}</BreadcrumbItem>
        );
      }

      const link = `${this.props.match.path}${item.link}`;
      return (
        <ListGroupItem tag={NavLink} key={item.title} to={link} action>
          {item.title}
        </ListGroupItem>
      );
    });

    const routes = pracownicyData.map((item) => {
      if (item.component) {
        return (
          <Route
            key={item.link}
            path={`${this.props.match.path}${item.link}`}
            component={pageComponents[item.component]}
          />
        );
      }
      return null;
    });

    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{this.props.rootSection}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={this.props.match.path}>{this.props.section}</Link>
          </BreadcrumbItem>
          {currentBreadcrumb}
        </Breadcrumb>

        <Row>
          <Col xs="12" sm="3">
            <ListGroup>{menu}</ListGroup>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://klient.interrisk.pl/EduPlusOnline?u=fkfbfwzrua">
              {' '}
              <Button
                color="danger"
                size="lg"
                className="sidebarDeclarationButton">
                <i className="fa fa-chevron-circle-right" />
                Kup ubezpieczenie
                <br />
              </Button>
              <br />
            </a>
          </Col>
          <Col xs="12" sm="9">
            <Switch>
              {routes}
              {this.props.isAuthenticated && (
                <Route
                  path={`/nnw2024/kup/`}
                  render={(props) => (
                    <Dynamic
                      {...props}
                      guid="2ac33822-edfa-40af-b11e-a929e2fafaf4"
                      // key={props.match.params.guid}
                    />
                  )}
                />
              )}
              <Redirect exact from="/nnw2024" to="/nnw2024/opis/" />
              <Redirect
                from="/nnw2024/kup/"
                to={{
                  pathname: '/login',
                  state: { referrer: this.props.location.pathname }
                }}
              />
              <Redirect
                to={{
                  pathname: '/login',
                  state: { referrer: this.props.location.pathname }
                }}
              />
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null
    // username: state.auth.user.name,
    // menu: state.menu.data ? state.menu.data.menu : []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logout: () => dispatch(logout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicTwoColumnTemplate);
